export const products = {
    // LIVE PRODUCTS
    // "tvi" :  [
    //     {
    //         id:"prod_NQfi6YPo6DgDAz",
    //         name: "TripValet Incentives PRO Escape",
    //         price:"price_1MfoMzIB7ByDDUZGM9Dm7BBu"
    //     },
    //     {
    //         id:"prod_NQfjuq8t2vAbEp",
    //         name: "TVI 2.0 Launch Offer",
    //         price:"price_1MfoOSIB7ByDDUZGaCBw3t5r"
    //     },
    //     {
    //         id:"prod_NQflUMfj9Gcecq",
    //         name: "TripValet Incentives Monthly",
    //         price:"price_1MfoQCIB7ByDDUZGj3hbvrnj"
    //     }
    // ]
    // TEST PRODUCTS
    "tvi": [
        {
            id: "prod_NscGBuXgygyhjA",
            name: "TripValet Incentives Monthly",
            price: "price_1N6r28IB7ByDDUZGgeza8yoE"
        },
        {
            id: "prod_NscIlsJ9eMROmo",
            name: "TVI 3 Month Membership",
            price: "price_1N6r4NIB7ByDDUZGUooyFuye"
        },
        {
            id: "prod_NscJ6xruBfJBfn",
            name: "TVI 6 Month Membership",
            price: "price_1N6r5JIB7ByDDUZGYog3q7ew"
        },
        {
            id: "prod_NscKu3yQbLAvu3",
            name: "TVI 12 Month Membership",
            price: "price_1N6r5qIB7ByDDUZGycdREpu1"
        },
        {
            id: "prod_NscKu3yQbLAvu3",
            name: "TVI 12 Month Membership",
            price: "price_1PVSxiIB7ByDDUZGRUdhowHO"
        }
    ]

}

